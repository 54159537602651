import { Link } from "react-router-dom";

import "./Footer.css";

const Footer = () => {
  return (
    <>
      <footer className="footerParallax footer">
        <div className="footer-widget">
          <div className="container mb-4">
            <div className="footer-left">
              <h3 className="text-white line__yellow">
                K2 Group strives to be your most <br /> important partner.
              </h3>

              <div className="footer_quick_links">
                <ul>
                <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li>
                    <Link to="/About">About us</Link>
                  </li>
                  <li>
                    <Link to="/Portfolio">Portfolio </Link>
                  </li>
                  <li>
                    <a href="https://k2groupindia.com/blog/" target="_blank">
                      Blog
                    </a>
                  </li>
                </ul>
              </div>
            </div>

            <div className="footer-right">
              <p className="fw-bold">
                Email <br />
                <a href="mailto:hello@K2groupindia.com">
                  hello@K2groupindia.com
                </a>
              </p>

              <div className="footer_social_icon">
                <p className="fw-bold">Follow Us</p>

                <div className="d-flex">
                  <a
                    href="https://www.facebook.com/K2GroupIndia/"
                    target="_blank"
                  >
                    <span>
                      <i className="fab fa-facebook-f"></i>
                    </span>
                  </a>

                  <a
                    href="https://www.instagram.com/k2groupindia/?hl=en"
                    target="_blank"
                  >
                    <span>
                      <i className="fab fa-instagram"></i>
                    </span>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="container align-items-center ">
            <div className="disclaimer">
              <p className="mb-0">
                © 2022 K2 Group India, L.L.C. All rights reserved. | Legal
              </p>
            </div>

            <Link to="/">
              <img
                src="../images/logo.svg"
                alt="logo"
                className="footer__logo mhide"
              />
            </Link>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
