import "./Portfolio.css";

import PortfolioCard from "../component/PortfolioCard";

const Portfolio = (props) => {
  return (
    <>
      <div className="wrapper">
        {/* =======: Banner section :: ====== */}
        <section className="section section__aboutbanner">
          <div className="container">
            <div className="about-content">
              <div className="row">
                <div className="col-md-6 col-12">
                  <div className="about-banner-heading">
                    <h1 className="mb-md-0 mb-4">Portfolio</h1>
                  </div>
                </div>
                <div className="col-md-6 col-12">
                  <div className="cnt_top ">
                    <p className="lead">
                      K2 Group India is an Indian Conglomerate driven by
                      business processes that are dynamic, Innovation quality
                      that is world class and an innovative approach to
                      understanding market dynamics
                    </p>
                    <p className="lead">
                      With a vision to cater the needs of the nation, meeting
                      the challenges of Indian markets and contributing towards
                      the country's economyK2 Group India moved its core
                      competence into diversified ventures.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* =======: Line section :: ====== */}

        <section className="section">
          <div className="container">
            <div className="scroll_wrapper position-relative">
              <a href="#down" className="text-white position-relative">
                SCROLL DOWN
                <i className="fa-solid fa-arrow-down-long ms-3"></i>
              </a>
              <span className="line"> </span>
            </div>
          </div>
        </section>
        <section className="section section__cards" id="down">
          <div className="container-fluid px-md-5">
            <PortfolioCard
              cardImg="../images/portfolio/project1.jpg"
              cardImgalt="Project1 image"
              cardTitle="Kings Auto"
              cardLink="https://kingsauto.in/"
              cardTarget="_blank"
              cardP1=" KINGS AUTO is a pioneer in buying and selling well-crafted,
                    contemporary styled, pre-owned premium luxury cars in India."
              cardP2="Our collection includes the latest and the rarest plush
                    beasts that are sure to make the eyes roll and heart beats
                    go for a stroll."
            />
            <PortfolioCard
              cardImg="../images/portfolio/project2.jpg"
              cardImgalt="Project2 image"
              cardTitle="Pet Voyage"
              cardLink="http://www.petvoyage.com/"
              cardTarget="_blank"
              cardP1="Pet Voyage is a total pet service provider that offers
              training and grooming services to your pets such that they
              look and feel their very best everyday."
              cardP2="Extending all possible support in your journey as a pet
              parent - fulfilling all his/her needs and nurturing him/her
              to cherish cheerful snouts, wiggling tails and loving licks,
              Pet Voyage offers just the best."
            />

            <PortfolioCard
              cardImg="../images/portfolio/project3.jpg"
              cardImgalt="Project3 image"
              cardTitle="Dear Pet"
              cardLink="https://dearpet.in/"
              cardTarget="_blank"
              cardP1="Dear Pet is the name that visions to comfort pets and pet
              parents by catering the market of pet essentials."
              cardP2="It aims to reach at every doorstep around the globe &amp;
              serve the best quality products for your pets, ranging from
              all kinds of Food to Grooming,Clothing, Accessories,
              Utilities, Travel Gears &amp; Innovative Toys."
            />

            <PortfolioCard
              cardImg="../images/portfolio/project4.jpg"
              cardImgalt="Project4 image"
              cardTitle="Ads n Url"
              cardLink="https://adsnurl.com/"
              cardTarget="_blank"
              cardP1="One of the fastest growing digital agencies in Asia. A
              pioneer in developing the highest quality-perfection custom
              mobile apps, Ads N Url incorporates the latest tools and
              technologies required to achieve the best results."
              cardP2=" Ads N Url is where technology collaborates with thoughts.
              Helping brands with unique niche establish their presence in
              the online sphere."
            />

            <PortfolioCard
              cardImg="../images/portfolio/project5.jpg"
              cardImgalt="Project5 image"
              cardTitle="Actors Cricket Bash"
              cardLink="#"
              cardTarget="_self"
              cardP1="A league filled with entertainment and stars. It combines
              India’s two favourite things."
              cardP2="  Cricket and the favourite daily soap actors. ACB aims to
              bring on its line-up all the popular actors who share a
              common zeal for cricket. It is not surprising therefore that
              ACB has been received very well garnering much appreciation
              from all quarters."
            />

            <PortfolioCard
              cardImg="../images/portfolio/project6.jpg"
              cardImgalt="Project6 image"
              cardTitle="White Pencil"
              cardLink="https://thewhitepencil.com/"
              cardTarget="_blank"
              cardP1="A creative agency, well versed in the field of design,
              branding and marketing."
              cardP2="It understands how important it is for a brand to have an
              identity of its own and aims to change the branding game on
              its own terms. Providing a complete end to end Online growth
              solution."
            />

            <PortfolioCard
              cardImg="../images/portfolio/project7.jpg"
              cardImgalt="Project7 image"
              cardTitle="The Chilli Project"
              cardLink="#"
              cardTarget="_self"
              cardP1="Food that takes you straight to the mexican feel, with a
              desi Indian Tadka."
              cardP2="TCP is a burst of flavour in the heart of Delhi. Serving
              excellence and Brilliant fusion taste satisfying all the
              hunger pangs."
            />

            <PortfolioCard
              cardImg="../images/portfolio/project8.jpg"
              cardImgalt="Project8 image"
              cardTitle="Fortune keys"
              cardTarget="_blank"
              cardLink="https://fortunekeys.in/"
              cardP1="Fortune Keys is a real estate company offering a wide range
              of luxurious property options at budgetary and competitive
              market rates, with 200+ investment professionals assisting
              you to make an informed decision."
              cardP2=" What sets it apart from others is the luxurious levels of
              comfort and quality, easy turn-outs, and hassle-free
              property acquisitions. With seven years of experience and a
              vision to become a gamechanger in the real estate realm,
              Fortune Keys has been building enduring client relationships
              with its accountability and credibility."
            />
          </div>
        </section>
      </div>
    </>
  );
};

export default Portfolio;
