function TeamCard(props) {
  return (
    <>
      <div className="col-md-4  col-6">
        <div className=" d-flex justify-content-center align-items-center">
          <div>
            <img
              src={props.TeamCardImg}
              alt={props.TeamCardImgalt}
              className="rounded-circle  mb-4"
            />
            <div className=" text-center">
              <h5 className="primary_color"> {props.TeamCardname}</h5>
              <p className="text-muted f16"> {props.TeamCardPosition}</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default TeamCard;
