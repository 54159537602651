import React from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import "./Home.css";

const owlOptions1 = {
  autoplayTimeout:4000 
};

const Home = () => {
  return (
    <>
      <div className="wrapper">
        {/* =======: Banner  section :: ====== */}
        <OwlCarousel className="owl-theme owl__banner" items="1" autoplay loop {...owlOptions1}>
          <div className="item position-relative">
            <div>
              <img
                className="mhide"
                src="../images/banner/slide1.jpg"
                alt="Saarthi Foundation"
              />
              <img
                className="mshow"
                src="../images/banner/slide1m.png"
                alt="Saarthi Foundation"
              />
            </div>
            <div className="layer_section">
              <div className="container">
                <div className="row">
                  <div className="col-md-10 col-lg-5 col-sm-10 col-xl-5 col_slll">
                    <div className="d-flex flex-column justify-content-between sl_height">
                      <div>
                        <h1 className="title ">
                          Future made better with a change that matters.
                        </h1>
                      </div>
                      <div>
                        <div className="section_wise">
                          <div className="f-flex align-items-center">
                            <h4>
                              Saarthi Foundation
                              <span className="line_parent">
                                <span className="line_child"></span>
                              </span>
                            </h4>
                          </div>
                          <p className=" f16">
                            illuminating lives of the underprivileged,
                            <br className="hide_mob" /> one day at a time.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="item position-relative ads_slide">
            <div>
              <img
                className="mhide"
                src="../images/banner/slide2.jpg"
                alt="Ads n Url slide"
              />
              <img
                className="mshow"
                src="../images/banner/slide2m.jpg"
                alt="Ads n Url slide"
              />
            </div>
            <div className="layer_section">
              <div className="container">
                <div className="row">
                  <div className="col-md-10 col-lg-5 col-sm-10 col-xl-5 col_slll">
                    <div className="d-flex flex-column justify-content-between sl_height">
                      <div>
                        <h1 className="title ">
                          Crafting Mobile App Experiences that Transform the
                          Industry.
                        </h1>
                      </div>
                      <div>
                        <div className="section_wise">
                          <div className="f-flex align-items-center">
                            <h4>
                              Ads n Url
                              <span className="line_parent">
                                <span className="line_child"></span>
                              </span>
                            </h4>
                          </div>
                          <p className=" f16">
                            300+ Creative folks crafting Experiences,
                            <br className="hide_mob" /> building Brands, and
                            developing the next big Future Tech.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="item position-relative">
            <div>
              <img
                className="mhide"
                src="../images/banner/slide3.jpg"
                alt="Dear Pet slide"
              />
              <img
                className="mshow"
                src="../images/banner/slide3m.jpg"
                alt="Dear Pet slide"
              />
            </div>
            <div className="layer_section">
              <div className="container">
                <div className="row">
                  <div className="col-md-10 col-lg-5 col-sm-10 col-xl-5 col_slll">
                    <div className="d-flex flex-column justify-content-between sl_height">
                      <div>
                        <h1 className="title ">
                          Treating the loyals like royal.
                        </h1>
                      </div>
                      <div>
                        <div className="section_wise">
                          <div className="f-flex align-items-center">
                            <h4>
                              Dear Pet
                              <span className="line_parent">
                                <span className="line_child"></span>
                              </span>
                            </h4>
                          </div>
                          <p className=" f16">
                            The One stop shop for all your Pet Need
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="item position-relative">
            <div>
              <img
                className="mhide"
                src="../images/banner/slide4.jpg"
                alt="First slide"
              />
              <img
                className="mshow"
                src="../images/banner/slide4m.jpg"
                alt="First slide"
              />
            </div>
            <div className="layer_section">
              <div className="container">
                <div className="row">
                  <div className="col-md-10 col-lg-5 col-sm-10 col-xl-5 col_slll">
                    <div className="d-flex flex-column justify-content-between sl_height">
                      <div>
                        <h1 className="title ">
                          Your Pet's Journey, Refined and Redefined
                        </h1>
                      </div>
                      <div>
                        <div className="section_wise">
                          <div className="f-flex align-items-center">
                            <h4>
                              Pet Voyage
                              <span className="line_parent">
                                <span className="line_child"></span>
                              </span>
                            </h4>
                          </div>
                          <p className=" f16">
                            Your Pet's own at home grooming service
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="item position-relative">
            <div>
              <img
                className="mhide"
                src="../images/banner/slide5.jpg"
                alt="Pellet Drum slide"
              />
              <img
                className="mshow"
                src="../images/banner/slide5m.jpg"
                alt="Pellet Drum slide"
              />
            </div>
            <div className="layer_section">
              <div className="container">
                <div className="row">
                  <div className="col-md-10 col-lg-5 col-sm-10 col-xl-5 col_slll">
                    <div className="d-flex flex-column justify-content-between sl_height">
                      <div>
                        <h1 className="title ">
                          The Folklore of Music, Melody and Inspiration.
                        </h1>
                      </div>
                      <div>
                        <div className="section_wise">
                          <div className="f-flex align-items-center">
                            <h4>
                              Pellet Drum Productions
                              <span className="line_parent">
                                <span className="line_child"></span>
                              </span>
                            </h4>
                          </div>
                          <p className=" f16">
                            Finest talent acquisition &amp; broadcasting
                            platform that enables aspiring singers to be seen,
                            heard, &amp; known, far &amp; wide.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </OwlCarousel>

        {/* =======: client section :: ====== */}

        <section className="section section__client">
          <div className="container">
            <div className="row">
              <div className="col-md-6 col-12">
                <p className="yellow-heading mb-4">Featured In</p>
              </div>

              <div className="col-md-6 col-12">
                <div className="row">
                  <div className="col-md-4 col-6 mb-3">
                    <div className="featuredIn">
                      <img
                        src="../images/client/zee-business.png"
                        alt="zee-business"
                      />
                    </div>
                  </div>
                  <div className="col-md-4 col-6 mb-3">
                    <div className="featuredIn">
                      <img src="../images/client/the-week.png" alt="the week" />
                    </div>
                  </div>

                  <div className="col-md-4 col-6 mb-3">
                    <div className="featuredIn">
                      <img
                        src="../images/client/the-economic-time.png"
                        alt="the-economic-time "
                      />
                    </div>
                  </div>
                  <div className="col-md-4 col-6 mb-3">
                    <div className="featuredIn">
                      <img
                        src="../images/client/businesstoday.png"
                        alt="business today "
                      />
                    </div>
                  </div>
                  <div className="col-md-4 col-6 mb-3">
                    <div className="featuredIn">
                      <img
                        src="../images/client/business-standard.png"
                        alt="business standard"
                      />
                    </div>
                  </div>
                  <div className="col-md-4 col-6 mb-3">
                    <div className="featuredIn">
                      <img
                        src="../images/client/hindistantimes.png"
                        alt="hindistan times"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* =======: Foundation section :: ====== */}

        <section className="section section__foundation">
          <div className="container">
            <div className="row">
              <div className="col-md-6  col-12">
                <div className="row">
                  <div className="col-xl-10 section-title-sec">
                    <p className="yellow-heading mb-md-5 mb-4 ">
                      Our CSR Initiative
                    </p>

                    <h2 className="section-title  mb-md-5 mb-4">
                      Saarthi Foundation
                    </h2>
                    <p className="sec-title-para text-muted">
                      Saarthi is not just a name, it is a spirit of
                      selflessness, with the committed aim of giving wings to
                      the new hopes, Saarthi Foundation stands tall in providing
                      aid in the education and living expenses of hundreds of
                      indigent children yearly. Under the flagship of K2 Group
                      India
                    </p>
                    <a
                      href="https://business.facebook.com/besaarthi"
                      target="_blank"
                      className="theme_btn btn mb-md-0 mb-5"
                    >
                      View More
                      <i className="fa-solid fa-arrow-right-long"></i>
                    </a>
                  </div>
                </div>
              </div>

              <div className="col-md-6 col-12">
                <div className="row align-items-center">
                  <div className="col-6">
                    <div className="img_wrapper mb-4">
                      <img
                        src="../images/saarthi1.png"
                        alt="saarthi foundation"
                      />
                    </div>
                    <div className="img_wrapper">
                      <img src="../images/saarthi2.png" alt="NGO foundation" />
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="img_wrapper">
                      <img
                        src="../images/saarthi3.png"
                        alt="saarthi1 foundation"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* =======: Founders section :: ====== */}

        <section className="section section__founders">
          <div className="container">
            <div className="row">
              <div>
                <p className="yellow-heading mb-5">Our Founders</p>
              </div>
            </div>

            <div className="row">
              <div className="col-md-6 col-12 mb-3">
                <div className="section-title-sec-k2 h-100">
                  <span className="tick1"></span>
                  <span className="tick2"></span>
                  <div className="our-founders">
                    <p className="get_para_h">
                      The man behind the entire conglomerate. A self made
                      entrepreneur, starting from the number one to now having
                      23+ ventures under his successful conglomerate.
                    </p>
                    <div className="founder-sec">
                      <div>
                        <img
                          src="../images/about/team/mohit-bansal.png"
                          alt="Founder"
                        />
                      </div>
                      <div className="founder-name">
                        <p className="f36">Mohit Bansal</p>
                        <span className="f20">Founder, K2 Group India</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-6 col-12 mb-3">
                <div className="section-title-sec-k2 h-100">
                  <span className="tick1"></span>
                  <span className="tick2"></span>
                  <div className="our-founders">
                    <p className="set_para_h">
                      The technical mind behind every venture who strategize
                      design and develop business models which has been the
                      reason for overall success of 23+ Ventures that fall under
                      K2 group india.
                    </p>
                    <div className="founder-sec mt__30">
                      <div>
                        <img
                          src="../images/about/team/shubham-bansal.png"
                          alt="Founder"
                        />
                      </div>
                      <div className="founder-name">
                        <p className="f36">Shubham Bansal</p>
                        <span className="f20">Founder, K2 Group India</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* =======:  Venture Section :: ====== */}

        <section className="section section__venture">
          <div className="container">
            <div className="row gx-5">
              <div className=" col-md-6  col-12">
                <p className="yellow-heading mb-md-5 mb-4">Our Ventures</p>

                <div className="upper-venture-one pe-xl-5">
                  <h2 className="mb-5 ">4 years, 17+ Ventures</h2>
                  <p className="mb-4">
                    We are a conglomerate supporting multiple ideas grow into
                    successful ventures.with an aim to conquer new horizons, its
                    commitment and steadfast drive to excel and achieve, fuels
                    its aspirations.
                  </p>

                  <p className="mb-4">
                    K2 Group India was established in the year 2012, by Mr.
                    Mohit Bansal as the founder and Mr. Shubham Bansal as the
                    co-founder. K2 Group India is an Indian Conglomerate
                    supporting multiple ideas to grow into successful ventures.
                  </p>

                  <p className="mb-4">
                    With a vision to cater the needs of the nation, meeting the
                    challenges of Indian markets and contributing towards the
                    country's economy, K2 Group India is on a continuous journey
                    to make a great impact in the society with every venture it
                    acquires.
                  </p>
                </div>
              </div>

              <div className=" col-md-6  col-12 venture-mr-unset">
                <div className="section-venture-k2-image">
                  <div className="row align-items-center">
                    <div className="col-4">
                      <div className="venture-img">
                        <a href="#">
                          <img
                            src="../images/venture/pet-voyage.png"
                            alt="pet-voyage"
                          />
                        </a>
                      </div>
                    </div>
                    <div className="col-4">
                      <div className="venture-img">
                        <a href="https://dearpet.in/" target="_blank">
                          <img
                            src="../images/venture/dearpet.png"
                            alt="dearpet logo"
                          />
                        </a>
                      </div>
                    </div>

                    <div className="col-4">
                      <div className="venture-img">
                        <a href="#">
                          <img src="../images/venture/el.png" alt="el logo" />
                        </a>
                      </div>
                    </div>

                    <div className="col-4">
                      <div className="venture-img">
                        <a href="#">
                          <img src="../images/venture/thc.png" alt="thc logo" />
                        </a>
                      </div>
                    </div>

                    <div className="col-4">
                      <div className="venture-img">
                        <a href="#">
                          <img
                            src="../images/venture/pellet-drum.png"
                            alt="pellet-drum logo"
                          />
                        </a>
                      </div>
                    </div>

                    <div className="col-4">
                      <div className="venture-img">
                        <a href="#">
                          <img
                            src="../images/venture/belgium.png"
                            alt="belgium logo"
                          />
                        </a>
                      </div>
                    </div>
                    <div className="col-4">
                      <div className="venture-img">
                        <a href="#">
                          <img
                            src="../images/venture/the-chilli-project.png"
                            alt="the chilly Project logo"
                            className="chilli_project"
                          />
                        </a>
                      </div>
                    </div>

                    <div className="col-4">
                      <div className="venture-img">
                        <a href="#">
                          <img
                            src="../images/venture/adsnurl.png"
                            alt="Ads n Url Logo"
                          />
                        </a>
                      </div>
                    </div>
                    <div className="col-4">
                      <div className="venture-img">
                        <a href="https://thewhitepencil.com/" target="_blank">
                          <img
                            src="../images/venture/white-pencil.png"
                            alt="White Pencil logo"
                            className="white_pencil"
                          />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* =======:  about Section :: ====== */}

        <section className="section section__about">
          <div className="container">
            <div className="row">
              <div className=" col-md-6 col-12">
                <div className="row">
                  <div className="col-lg-10 section-title-sec">
                    <p className="yellow-heading  mb-md-5 mb-4">About Us</p>

                    <h2 className="section-title   mb-md-5 mb-4">
                      We turn ideas <br />
                      into Ventures
                    </h2>
                    <p className="sec-title-para mb-4">
                      Driven by business processes that are dynamic, with an
                      innovative approach to understanding market demands, we
                      expand our journey with each venture aquired.
                    </p>
                  </div>
                </div>
              </div>

              <div className=" col-md-6  col-12">
                <div className="row align-items-center">
                  <div className="col-md-8 col-sm-12 col-xs-12">
                    <div className="img_wrapper mb-4">
                      <img src="../images/about1.png" alt="about1 images" />
                    </div>
                    <div className="row">
                      <div className="col-6">
                        <div>
                          <div className="img_wrapper">
                            <img
                              src="../images/about2.jpg"
                              alt="about2 images"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="img_wrapper">
                          <img src="../images/about3.png" alt="about3 images" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4 col-6">
                    <div className="img_wrapper">
                      <img src="../images/about4.png" alt="about4 images" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default Home;
