import React from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import "./About.css";
import TeamCard from "../component/TeamCard";

const owlOptions = {
  responsiveClass: true,
  margin: 25,
  loop:true,
  nav: true,
  dots: false,
  responsive: {
    0: {
      items: 1,
    },
    1000: {
      items: 3,
    }
  },
};


const About = (props) => {
  return (
    <>
      <div className="wrapper"> 
      {/* =======: Banner section :: ====== */} 
      <section className="section section__aboutbanner">
       <div className="container">
         <div className="about-content">
           <div className="about-banner-heading">
             <h1 className="mb-4"> Helping Ideas mutate into Fully <br /> Functional Ventures </h1>
             <p className="mb-4"> Valuable additions to the market and the community </p>
           </div>
           <div className="aboutpage-banner">
             <img className="w-100 mshow" src="../images/about/abt_topp_mobile.png" alt="banner mobile top" />
             <img className="w-100 mhide" src="../images/about/abt_topp.jpg" alt="banner abt_topp top" />
           </div>
         </div>
       </div>
     </section> {/* =======: mission section :: ====== */} <section className="section our-mission-sec">
       <div className="container">
         <div className="row section-text">
           <div className="col-xl-11">
             <div className="aboutbanner-ourmission">
               <p className="yellow-heading mb-md-5 mb-4">Our Mission</p>
               <h3> To cater to the needs of the nation, meeting the challenges of Indian markets, and make significant contributions to the country’s economy we are on a continuous journey to impact society with every venture acquired. </h3>
             </div>
           </div>
         </div>
       </div>
     </section>
      {/* =======: section Value :: ====== */}
       <section className="section section__value">
       <div className="container">
         <p className="yellow-heading mb-md-5 mb-4">Our Value</p>
         <div className="row">
           <div className="col-md-6">
             <p className="text-muted mb-md-5 mb-4"> The dream to be on the peak brings with it certain values we abide by This keeps us committed to our mission and a step closer to our goals. </p>
             <img src="../images/about/our-value.png" alt="our value"  className="mb-md-0 mb-4"/>
           </div>
           <div className="col-md-6">
             <div className="ov-right-card">
               <div className="row">
                 <div className="col-md-1 col-12">
                   <img src="../images/about/lighting.png" alt="lighting" className="mb-md-0 mb-4" />
                 </div>
                 <div className="col-md-11 col-12">
                   <div>
                     <h4 className="fw-500">Entrepreneurial Empowerment</h4>
                     <p className="f16"> We envision fast growth in each acquisition by creating powerful leaders by inculcating in them the right skills with powerful mentorship. </p>
                   </div>
                 </div>
               </div>
             </div>
             <div className="ov-right-card">
               <div className="row">
                 <div className="col-md-1 col-12">
                   <img src="../images/about/earnings.png" alt="earnings" className="mb-md-0 mb-4" />
                 </div>
                 <div className="col-md-11 col-12">
                   <div>
                     <h4 className="fw-500">Deliver Experiences</h4>
                     <p className="f16"> We work with the best, to deliver the most excellent end result to our end users. We work with the aim to not just provide a service or a tangible good, but map out a user journey and create an experience, to connect everyone we cater to. </p>
                   </div>
                 </div>
               </div>
             </div>
           </div>
         </div>
       </div>
     </section>
      {/* =======: Our Team :: ====== */}
       <section className="section section__team">
       <div className="container">
         <p className="yellow-heading mb-md-5">Our Team</p>
         <div className="row mb-5">
           <div className="col-md-6">
             <h2 className=" section-title mb-md-5 mb-4"> Meet the minds shaping the industry </h2>
           </div>
           <div className="col-md-6">
             <p> The dream to be on the peak brings with it certain values we abide by This keeps us committed to our mission and a step closer to our goals. </p>
           </div>
         </div>
         <h4 className="text-center mb-md-5 mb-4">Our Founders</h4>
         <div className="row mb-md-5 mb-4">
           <div className="col-md-6  col-12">
             <div className=" d-flex justify-content-center align-items-center">
               <div className="teams__img"> 
                 <img src="../images/about/team/mohit-bansal.png" alt="Founder" className="rounded-circle  mb-4" />
                 <div className="text-center">
                   <h5 className="primary_color">Mohit Bansal</h5>
                   <p className="text-muted lead">Founder, K2 Group India</p>
                 </div>
               </div>
             </div>
           </div>
           <div className="col-md-6 col-12">
             <div className=" d-flex justify-content-center align-items-center">
             <div className="teams__img"> 
                 <img src="../images/about/team/shubham-bansal.png" alt="Founder" className="rounded-circle  mb-4" />
                 <div className="text-center">
                   <h5 className="primary_color">Shubham Bansal</h5>
                   <p className="text-muted lead">Founder, K2 Group India</p>
                 </div>
               </div>
             </div>
           </div>
         </div>
         <div className="text-center">
           <h4 className="text-center mb-md-5 mb-4">Our Executives</h4>
         </div>
         <div className="row justify-content-center  mb-md-5 mb-4">


<TeamCard TeamCardImg="../images/about/team/puneet-sehgal.png" TeamCardImgalt="CFO image" TeamCardname="Puneet Sehgal" TeamCardPosition="CFO" />
<TeamCard TeamCardImg="../images/about/team/Kamlesh.png" TeamCardImgalt="CA image" TeamCardname="Kamlesh" TeamCardPosition="CA" />
<TeamCard TeamCardImg="../images/about/team/shivam-srivastava.png" TeamCardImgalt="CMO image" TeamCardname="Shivam Srivastava" TeamCardPosition="CMO" />
           
         </div>
       </div>
     </section>
      {/* =======: Our Office :: ====== */} 
      <section className="section section__offices">
       <div className="container">
         <div className="row">
           <div className="col-md-3">
             <p className="yellow-heading mb-md-5 mb-4">Our Offices</p>
             <h2 className="section-title mb-md-5 mb-4"> Our Offices </h2>
           </div>
           <div className="col-md-9">
             <OwlCarousel className="owl-theme owl__about" {...owlOptions}>
               <div className="item">
                 <img src="../images/about/team/slide1.png" alt="slide1 images" />
               </div>
               <div className="item">
                 <img src="../images/about/team/slide2.png" alt="slide2 images" />
               </div>
               <div className="item">
                 <img src="../images/about/team/slide3.jpg" alt="slide3 images" />
               </div>
               <div className="item">
                 <img src="../images/about/team/slide4.jpg" alt="slide4 images" />
               </div>
               <div className="item">
                 <img src="../images/about/team/slide5.jpg" alt="slide5 images" />
               </div>
             </OwlCarousel>
           </div>
         </div>
       </div>
     </section>
   </div>
    </>
  );
};

export default About;
