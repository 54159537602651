function PortfolioCard(props) {
  return (
    <>
      {/* =======:   portfolio cards   :: ====== */}

      <div className="row align-items-center mb-100 gx-5">
        <div className="col-md-6 col-12 order-md-2">
          <img
            className="img-fluid"
            src={props.cardImg}
            alt={props.cardImgalt}
          />
        </div>
        <div className="col-md-6 col-12 order-md-1">
          <div className="pcard__content">
            <h2 className="section-title mb-md-5 mb-4">{props.cardTitle}</h2>
            <p className="text-muted"> {props.cardP1}</p>
            <p className="text-muted">{props.cardP2}</p>
            <a
              target={props.cardTarget}
              href={props.cardLink}
              className="theme_btn btn mb-md-0 mb-5"
            >
              Know More
              <i className="fa-solid fa-arrow-right-long"></i>
            </a>
          </div>
        </div>
      </div>
    </>
  );
}

export default PortfolioCard;
