import { logDOM } from "@testing-library/react";
import { Link } from "react-router-dom";

import "./Header.css";

const Header = () => {
  return (
    <>
      <nav className="navbar navbar-expand-lg navbar-dark site_header">
        <div className="container">
          <Link to="/" className="navbar-brand">
            <img src="../images/logo.svg" alt="logo" />
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>

          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
              <li className="nav-item">
                <Link className="nav-link active" to="/About">
                  About Us
                </Link>
              </li>

              <li className="nav-item">
                <Link className="nav-link active" to="/Portfolio">
                  Portfolio
                </Link>
              </li>

              <li className="nav-item">
                <a
                  className="nav-link active"
                  href="https://k2groupindia.com/blog/"
                  target="_blank"
                >
                  Blog
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Header;
